import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import {
  faEnvelope, faGlobeAmericas, faCheckDouble, faCheckCircle, faHandshake, faDatabase, faUserShield, faGavel, faFileSignature, faLock, faTimesCircle, faFileAlt, faGlobe, faFileContract,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import ProductCLM2 from '../images/product-clm-2.png';
import DemoForm from '../components/DemoForm';
import DashboardWaves from '../images/dashboard-starter-waves-es.svg';
import Dashboard from '../images/500-startups/dashboard-starter.svg';
import Dashboard2 from '../images/500-startups/dashboard-starter-2.svg';
import Logo500 from '../images/500-startups/logo-500.png';
import SemiCircle from '../images/500-startups/semi-circle.svg';
import MujerStarter from '../images/mujer-starter.jpg';
import ClmHero from '../images/trato-clm-hero.png';
import Firma1 from '../images/firma-1.png';
import Firma2 from '../images/firma-2.png';


function TratoVsDocusign({ pageContext: { locale }, location }) {
  const { t } = useTranslation()

  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing bg-grey" inverted border location={location}>

      <section className="bg-light">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="align-self-center">
                <h1>{t('tratoVsDocusign.pageTitle1')} <span className="font-weight-bold">{t('tratoVsDocusign.pageTitle2')}</span></h1>
                <p className="lead mb-3">{t('tratoVsDocusign.heroTitle1')} <span className="font-weight-bold underline-primary">{t('tratoVsDocusign.heroTitle2')}</span></p>
                <DemoForm type="trato-vs-docusign" />
              </div>
            </div>
            <div className="col-lg-6">
              <img src={Firma1} className="img-fluid" alt="Firma Electrónica vs Firma Electrónica Avanzada" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="text-center w-75 mx-auto">
            <h2 className="font-weight-bold mb-3">{t('tratoVsDocusign.title1')}</h2>
            <p className="lead text-muted">{t('tratoVsDocusign.text1')}</p>
          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container">
          <h2 className="font-weight-bold text-center">{t('tratoVsDocusign.title2')}</h2>
          <div className="row">
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faCheckDouble} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle1')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText1')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faFileSignature} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle2')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText2')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faDatabase} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle3')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText3')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faFileContract} className="lead text-primary align-baseline icon-sm align-self-center mx-auto" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle4')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText4')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faGavel} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle5')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText5')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faGlobeAmericas} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle6')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText6')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faFileAlt} className="lead text-primary align-baseline icon-sm align-self-center mx-auto" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle7')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText7')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-4 px-2">
              <div className="card bg-light shadow-sm rounded card-features">
                <div className="card-body p-3">
                  <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faCheckDouble} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                  <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle8')}</h5>
                  <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText8')}</p>
                </div>
              </div>
            </div>
            {/*   <div className="col-lg-4 my-4 px-2">
                        <div className="card bg-light shadow-sm rounded card-features">
                            <div className="card-body p-3">
                                <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faCheckDouble} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                                <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle9')}</h5>
                                <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText9')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-4 px-2">
                        <div className="card bg-light shadow-sm rounded card-features">
                            <div className="card-body p-3">
                                <div className="align-self-center p-2 badge-primary-50 circle mb-2"><div className="d-flex"><FontAwesomeIcon icon={faCheckDouble} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div>
                                <h5 className="font-weight-bold">{t('tratoVsDocusign.featuresTitle10')}</h5>
                                <p className="text-muted mb-0">{t('tratoVsDocusign.featuresText10')}</p>
                            </div>
                        </div>
                    </div> */}
          </div>
          <div className="heading text-center mx-auto mt-5 mb-0">
            <h2 className="d-inline-block">{t('clientsSection.heading1')} <span className="underline-primary font-weight-bold">{t('clientsSection.heading2')}</span></h2>
          </div>
          <Clients />
        </div>
      </section>

      <section>
        <div className="container">
          <h2 className="font-weight-bold text-center mb-3">{t('tratoVsDocusign.title5')}</h2>
          <div className="table-responsive shadow-sm">
            <table className="table table-striped rounded mb-0">
              <thead>
                <tr className="bg-light">
                  <td className="font-weight-bold border-top-0">{t('tratoVsDocusign.tableText1')}</td>
                  <td className="text-center font-weight-bold border-top-0">Docusign CLM</td>
                  <td className="text-center font-weight-bold border-top-0">Agiloft</td>
                  <td className="text-center font-weight-bold border-top-0">CLM Matrix</td>
                  <td className="text-center font-weight-bold border-top-0">Trato</td>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-light">
                  <td>{t('tratoVsDocusign.tableText2')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
                <tr className="bg-light">
                  <td scope="row">{t('tratoVsDocusign.tableText3')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
                <tr className="bg-light">
                  <td scope="row">{t('tratoVsDocusign.tableText4')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
                <tr className="bg-light">
                  <td scope="row">{t('tratoVsDocusign.tableText5')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
                <tr className="bg-light">
                  <td scope="row">{t('tratoVsDocusign.tableText6')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
                <tr className="bg-light">
                  <td scope="row">{t('tratoVsDocusign.tableText7')}</td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faTimesCircle} className="lead text-danger" /></td>
                  <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} className="lead text-success" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h2 className="font-weight-bold mb-3">{t('tratoVsDocusign.title3')}</h2>
              <p className="text-muted">{t('tratoVsDocusign.text3')}</p>
              <p className="text-muted">{t('tratoVsDocusign.text4')}</p>
            </div>
            <div className="col-lg-5">
              <img src={Firma2} className="img-fluid" alt="Firma Electrónica vs Firma Electrónica Avanzada" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-grey border-bottom">
        <div className="container">
          <div className="bg-light mx-auto rounded shadow-sm">
            <div className="row">
              <div className="col-lg-8 d-flex">
                <div className="align-self-center p-3">
                  <h2 className="font-weight-bold">{t('tratoClm.CtaTitle')}</h2>
                  <p className="lead text-muted mb-3">{t('tratoClm.CtaText')}</p>
                  <DemoForm type="trato-vs-docusign"/>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <div className="position-relative">
                  <div className="gradient-cta position-absolute h-100 w-100 rounded-right" />
                  <p className="position-absolute text-light gradient-text mb-0">{t('tratoStarter.testimony1')} <span className="font-weight-bold">{t('tratoStarter.testimony2')}</span></p>
                  <img src={MujerStarter} className="img-fluid rounded-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default TratoVsDocusign;
